import { GetVendorChargeTypesResponse } from '../domain/get-vendor-charges-types.response'
import { VendorChargeCode } from '../domain/vendor-charge-code.model'

export default function fromGetVendorChargeTypesResponse(
  request: GetVendorChargeTypesResponse
): VendorChargeCode[] {
  return request.map<VendorChargeCode>(requestItem => ({
    id: requestItem.uuid,
    vendorDescription: requestItem.vendorDescription,
    code: requestItem.code,
    description: requestItem.description,
    tenantChargeTypeId: requestItem.tenantChargeTypeUuid,
    chargeCode: {
      id: requestItem.chargeTypeId,
      description: requestItem.navixChargeDescription
    }
  }))
}
