import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { API_URL, HEADER_API_KEY } from '@navix/utils/tokens'
import { AddTenantChargeCodeRequest } from '../domain/add-tenant-charge-code.request'
import { UpdateTenantChargeCodeRequest } from '../domain/edit-tenant-charge-code.request'
import { ToogleDefaultTenantChargeCodeRequest } from '../domain/toggle-default-tenant-charge-code.request'
import { UpdateVendorChargeCodeRequest } from '../domain/edit-vendor-charge-code.request'
import { GetChargeTypesVarianceReasonsResponse } from '../domain/get-charge-types-variance-reasons.response'
import { Observable } from 'rxjs'
import { GetAccessorialChargeTypesResponse } from '../domain/get-accessorial-charge-types.response'
import { GetBaseChargeTypesResponse } from '../domain/get-base-charges-types.response'
import { GetVendorChargeTypesResponse } from '../domain/get-vendor-charges-types.response'
import { GetVendorChargeTypesByIdResponse } from '../domain/get-vendor-charges-types-by-id.response'
import { AddVendorChargeCodeRequest } from '../domain/add-vendor-charge-code.request'

@Injectable({
  providedIn: 'root'
})
export class ChargeCodesService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiKey = inject(HEADER_API_KEY)

  private readonly apiMiscellaneousUrl = `${this.apiUrl}miscellaneous/` as const

  private readonly headersWithApiKey: { [key: string]: string } = {
    ...this.apiKey
  } as { [key: string]: string }

  addTenantChargeCode(request: AddTenantChargeCodeRequest) {
    return this._http.post<void>(
      `${this.apiMiscellaneousUrl}tenant-charge-types`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  toggleDefaultChargeCode(request: ToogleDefaultTenantChargeCodeRequest) {
    return this._http.put<void>(
      `${this.apiMiscellaneousUrl}tenant-charge-types/${request.tenantChargeTypeUuid}`,
      { isDefault: request.isDefault },
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateTenantChargeCode(request: UpdateTenantChargeCodeRequest) {
    return this._http.put<void>(
      `${this.apiMiscellaneousUrl}tenant-charge-types`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteTenantChargeCode(id: string) {
    return this._http.delete<void>(
      `${this.apiMiscellaneousUrl}tenant-charge-types/${id}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  updateVendorChargeCode(request: UpdateVendorChargeCodeRequest) {
    return this._http.put<void>(
      `${this.apiMiscellaneousUrl}vendor-charge-types/${request.vendorChargeCodeUuid}`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getChargeTypes(): Observable<GetAccessorialChargeTypesResponse> {
    return this._http.get<GetAccessorialChargeTypesResponse>(
      `${this.apiMiscellaneousUrl}charge-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getChargeTypesVarianceReasons(): Observable<GetChargeTypesVarianceReasonsResponse> {
    return this._http.get<GetChargeTypesVarianceReasonsResponse>(
      `${this.apiUrl}invoices/vendor-invoice-charges/variance-reasons`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getBaseChargeTypes(): Observable<GetBaseChargeTypesResponse> {
    return this._http.get<GetBaseChargeTypesResponse>(
      `${this.apiMiscellaneousUrl}charge-types/parent`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getVendorChargeTypes(): Observable<GetVendorChargeTypesResponse> {
    return this._http.get<GetVendorChargeTypesResponse>(
      `${this.apiMiscellaneousUrl}vendor-charge-types`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  getVendorChargeTypeById(
    id: string
  ): Observable<GetVendorChargeTypesByIdResponse> {
    return this._http.get<GetVendorChargeTypesByIdResponse>(
      `${this.apiMiscellaneousUrl}vendor-charge-types/${id}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  deleteVendorChargeCode(id: string) {
    return this._http.delete<void>(
      `${this.apiMiscellaneousUrl}vendor-charge-types/${id}`,
      {
        headers: this.headersWithApiKey
      }
    )
  }

  addVendorChargeCode(request: AddVendorChargeCodeRequest) {
    return this._http.post<void>(
      `${this.apiMiscellaneousUrl}vendor-charge-types`,
      request,
      {
        headers: this.headersWithApiKey
      }
    )
  }
}
