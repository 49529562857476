import { inject } from '@angular/core'
import { createEffect, Actions, ofType } from '@ngrx/effects'
import { exhaustMap, of, catchError, map, switchMap, debounceTime } from 'rxjs'

import { ChargeCodesService } from '../../infrastructure/charge-codes.service'
import { vendorChargeCodesActions } from './vendor-charge-codes.actions'
import fromGetVendorChargeTypesResponse from '../../adapter/fromGetVendorChargeTypesResponse'
import fromGetVendorChargeTypesByIdResponse from '../../adapter/fromGetVendorChargeTypesByIdResponse'
import ToUpdateVendorChargeTypesRequest from '../../adapter/ToUpdateVendorChargeCodeRequest'
import ToAddVendorChargeTypesRequest from '../../adapter/ToAddVendorChargeCodeRequest'

export const load = createEffect(
  (actions$ = inject(Actions), service = inject(ChargeCodesService)) => {
    return actions$.pipe(
      ofType(vendorChargeCodesActions.load),
      exhaustMap(() =>
        service.getVendorChargeTypes().pipe(
          map(response =>
            vendorChargeCodesActions.loadSuccess({
              vendorChargeCodes: fromGetVendorChargeTypesResponse(response)
            })
          ),
          catchError(error => of(vendorChargeCodesActions.loadFail(error)))
        )
      )
    )
  },
  { functional: true }
)

export const resetLoadActionsLoader = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        vendorChargeCodesActions.loadSuccess,
        vendorChargeCodesActions.loadFail
      ),
      debounceTime(100),
      map(() => vendorChargeCodesActions.reserLoadLoadingState())
    )
  },
  {
    functional: true
  }
)

export const loadById = createEffect(
  (actions$ = inject(Actions), service = inject(ChargeCodesService)) => {
    return actions$.pipe(
      ofType(vendorChargeCodesActions.loadById),
      exhaustMap(action =>
        service.getVendorChargeTypeById(action.id).pipe(
          map(response =>
            vendorChargeCodesActions.loadByIdSuccess({
              vendorChargeCode: fromGetVendorChargeTypesByIdResponse(response)
            })
          ),
          catchError(error => of(vendorChargeCodesActions.loadByIdFail(error)))
        )
      )
    )
  },
  { functional: true }
)

export const resetLoadByIdActionsLoader = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        vendorChargeCodesActions.loadByIdSuccess,
        vendorChargeCodesActions.loadByIdFail
      ),
      debounceTime(100),
      map(() => vendorChargeCodesActions.resetLoadByIdLoadingState())
    )
  },
  {
    functional: true
  }
)

export const update = createEffect(
  (actions$ = inject(Actions), service = inject(ChargeCodesService)) => {
    return actions$.pipe(
      ofType(vendorChargeCodesActions.update),
      map(({ vendorChargeCode }) => ({
        request: ToUpdateVendorChargeTypesRequest(vendorChargeCode)
      })),
      exhaustMap(action =>
        service.updateVendorChargeCode(action.request).pipe(
          switchMap(() => of(vendorChargeCodesActions.updateSuccess())),
          catchError(error => of(vendorChargeCodesActions.updateFail(error)))
        )
      )
    )
  },
  { functional: true }
)

export const resetUpdateActionsLoader = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        vendorChargeCodesActions.updateSuccess,
        vendorChargeCodesActions.updateFail
      ),
      debounceTime(100),
      map(() => vendorChargeCodesActions.resetUpdateLoadingState())
    )
  },
  {
    functional: true
  }
)

export const deleteVendorChargeCode = createEffect(
  (actions$ = inject(Actions), service = inject(ChargeCodesService)) => {
    return actions$.pipe(
      ofType(vendorChargeCodesActions.delete),
      exhaustMap(action =>
        service.deleteVendorChargeCode(action.id).pipe(
          switchMap(() => of(vendorChargeCodesActions.deleteSuccess())),
          catchError(error => of(vendorChargeCodesActions.deleteFail(error)))
        )
      )
    )
  },
  { functional: true }
)

export const resetDeleteActionsLoader = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(
        vendorChargeCodesActions.deleteSuccess,
        vendorChargeCodesActions.deleteFail
      ),
      debounceTime(100),
      map(() => vendorChargeCodesActions.resetDeleteLoadingState())
    )
  },
  {
    functional: true
  }
)

export const addVendorChargeCode = createEffect(
  (actions$ = inject(Actions), service = inject(ChargeCodesService)) => {
    return actions$.pipe(
      ofType(vendorChargeCodesActions.add),
      map(({ vendorChargeCode }) => ({
        request: ToAddVendorChargeTypesRequest(vendorChargeCode)
      })),
      exhaustMap(action =>
        service.addVendorChargeCode(action.request).pipe(
          switchMap(() => of(vendorChargeCodesActions.addSuccess())),
          catchError(error => of(vendorChargeCodesActions.addFail(error)))
        )
      )
    )
  },
  { functional: true }
)
