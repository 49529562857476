import { UpdateVendorChargeCodeRequest } from '../domain/edit-vendor-charge-code.request'
import { VendorChargeCodesForm } from '../domain/vendor-charge-codes.form.model'

export default function ToUpdateVendorChargeTypesRequest(
  request: Pick<VendorChargeCodesForm, 'vendorChargeTypeId' | 'chargeTypeId'>
): UpdateVendorChargeCodeRequest {
  return {
    vendorChargeCodeUuid: request.vendorChargeTypeId,
    chargeTypeId: Number(request.chargeTypeId)
  }
}
