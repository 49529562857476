import { Injectable, inject } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { ChargeCodesService } from '../infrastructure/charge-codes.service'
import { ChargeCodesAdapter } from '../adapter/ChargeCodesAdapter'
import { chargeCodesActions } from './charge-codes.actions'
import {
  catchError,
  debounceTime,
  exhaustMap,
  map,
  of,
  switchMap,
  tap
} from 'rxjs'
import { ToAddTenantChargeCodesRequest } from '../adapter/ToAddTenantChargeCodesRequest'
import { ChargeCodesFacade } from './charge-codes.facade'
import { AsyncOperations } from '../domain/charge-codes-loading.model'
import { ToUpdateTenantChargeCodesRequest } from '../adapter/ToUpdateTenantChargeCodeRequest'
import { ToogleDefaultTenantChargeCodeRequest } from '../domain/toggle-default-tenant-charge-code.request'
import { LoadingStatuses } from '@navix/shared/loading'

import { ChargeCode } from '../domain/charge-type.model'
import { LegacyAlertsFacade } from '@navix/alerts/domain'

/**
 * @deprecated use functional effects instead
 */
@Injectable()
export class ChargeCodesEffects {
  private actions$ = inject(Actions)
  private alertsFacade = inject(LegacyAlertsFacade)

  private chargeCodesService = inject(ChargeCodesService)
  private chargeCodesAdapter = new ChargeCodesAdapter()
  private readonly chargeCodesFacade = inject(ChargeCodesFacade)

  constructor() {
    this.chargeCodesAdapter.set(ToAddTenantChargeCodesRequest)
    this.chargeCodesAdapter.set(ToUpdateTenantChargeCodesRequest)
  }

  addTenantChargeCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chargeCodesActions.addTenantChargeCode),
      map(chargeCode => ({
        request: this.chargeCodesAdapter.convert(
          ToAddTenantChargeCodesRequest,
          chargeCode
        )
      })),
      switchMap(({ request }) =>
        this.chargeCodesService.addTenantChargeCode(request).pipe(
          map(() => chargeCodesActions.addTenantChargeCodeSuccess()),
          catchError(httpError =>
            of(
              chargeCodesActions.addTenantChargeCodeFail({
                error: httpError
              })
            )
          )
        )
      )
    )
  )

  updateTenantChargeCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chargeCodesActions.updateTenantChargeCode),
      map(chargeCode => ({
        request: this.chargeCodesAdapter.convert(
          ToUpdateTenantChargeCodesRequest,
          chargeCode
        )
      })),
      switchMap(({ request }) =>
        this.chargeCodesService.updateTenantChargeCode(request).pipe(
          map(() => chargeCodesActions.updateTenantChargeCodeSuccess()),
          catchError(httpError =>
            of(
              chargeCodesActions.updateTenantChargeCodeFail({
                error: httpError.error
              })
            )
          )
        )
      )
    )
  )

  toggleDefaultChargeCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chargeCodesActions.toggleDefaultChargeCode),
      map(
        chargeCode =>
          <ToogleDefaultTenantChargeCodeRequest>{
            tenantChargeTypeUuid: chargeCode.id,
            isDefault: chargeCode.isDefault
          }
      ),
      switchMap(request =>
        this.chargeCodesService.toggleDefaultChargeCode(request).pipe(
          map(() =>
            chargeCodesActions.toggleDefaultChargeCodeSuccess({
              id: request.tenantChargeTypeUuid,
              isDefault: request.isDefault
            })
          ),
          catchError(httpError =>
            of(
              chargeCodesActions.toggleDefaultChargeCodeFail({
                error: httpError
              })
            )
          )
        )
      )
    )
  )

  resettAddTenantChargeCodeLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          chargeCodesActions.addTenantChargeCodeSuccess,
          chargeCodesActions.addTenantChargeCodeFail
        ),
        debounceTime(100),
        map(() =>
          chargeCodesActions.setLoading({
            operation: AsyncOperations.addTenantChargeCode,
            loading: LoadingStatuses.NotStarted,
            message: ''
          })
        )
      ),
    {}
  )

  resettUpdateTenantChargeCodeLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          chargeCodesActions.updateTenantChargeCodeSuccess,
          chargeCodesActions.updateTenantChargeCodeFail
        ),
        debounceTime(100),
        map(() =>
          chargeCodesActions.setLoading({
            operation: AsyncOperations.updateTenantChargeCode,
            loading: LoadingStatuses.NotStarted,
            message: ''
          })
        )
      ),
    {}
  )

  resettToggleDefaultChargeCodeLoading$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          chargeCodesActions.toggleDefaultChargeCodeSuccess,
          chargeCodesActions.toggleDefaultChargeCodeFail
        ),
        debounceTime(100),
        map(() =>
          chargeCodesActions.setLoading({
            operation: AsyncOperations.toggleDefaultChargeCode,
            loading: LoadingStatuses.NotStarted,
            message: ''
          })
        )
      ),
    {}
  )

  deleteTenantChargeCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chargeCodesActions.deleteTenantChargeCode),
      switchMap(({ id }) =>
        this.chargeCodesService.deleteTenantChargeCode(id).pipe(
          map(() => chargeCodesActions.deleteTenantChargeCodeSuccess()),
          catchError(httpError =>
            of(
              chargeCodesActions.deleteTenantChargeCodeFail({
                error: httpError.error
              })
            )
          )
        )
      )
    )
  )
  loadAccessorialChargeCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chargeCodesActions.loadAccessorialChargeCodes),
      switchMap(() =>
        this.chargeCodesService.getChargeTypes().pipe(
          map(response =>
            response.map<ChargeCode>(
              responseChargeType =>
                <ChargeCode>{
                  id: responseChargeType.chargeTypeId,
                  uuid: responseChargeType.uuid,
                  description: responseChargeType.description,
                  parentChargeTypeId:
                    responseChargeType.parentChargeTypeId ?? undefined,
                  sortOrder: responseChargeType.sortOrder,
                  groupId:
                    responseChargeType.accessorialChargeTypeGroupId ??
                    undefined,
                  tenantChargeTypes: responseChargeType.tenantChargeTypes.map(
                    tenantChargeType =>
                      <ChargeCode['tenantChargeTypes'][number]>{
                        id: tenantChargeType.uuid,
                        code: tenantChargeType.code,
                        description: tenantChargeType.description ?? '',
                        isDefault: tenantChargeType.isDefault,
                        isOverride: tenantChargeType.isOverride ?? false
                      }
                  )
                }
            )
          ),
          map(chargeTypes =>
            chargeCodesActions.loadAccessorialChargeCodesSuccess({
              chargeTypes
            })
          ),
          catchError(error =>
            of(
              chargeCodesActions.loadAccessorialChargeCodesFail({
                error
              })
            )
          )
        )
      )
    )
  )

  resettDeleteTenantChargeCodeLoading$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        chargeCodesActions.deleteTenantChargeCodeSuccess,
        chargeCodesActions.deleteTenantChargeCodeFail
      ),
      debounceTime(100),
      map(() =>
        chargeCodesActions.setLoading({
          operation: AsyncOperations.deleteTenantChargeCode,
          loading: LoadingStatuses.NotStarted,
          message: ''
        })
      )
    )
  )

  loadChargeCodesVarianceReasons$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chargeCodesActions.loadChargeCodesVarianceReasons),
      exhaustMap(() =>
        this.chargeCodesService.getChargeTypesVarianceReasons().pipe(
          map(response =>
            response.map(
              item =>
                <ChargeCode>{
                  id: item.chargeTypeId,
                  varianceReasons: item.varianceReasons.map(
                    varianceReason =>
                      <ChargeCode['varianceReasons'][number]>{
                        id: varianceReason.varianceReasonId,
                        description: varianceReason.description
                      }
                  )
                }
            )
          ),
          map(chargeTypes =>
            chargeCodesActions.loadChargeCodesVarianceReasonsSuccess({
              chargeTypes
            })
          ),
          catchError(error =>
            of(
              chargeCodesActions.loadChargeCodesVarianceReasonsFail({
                error
              })
            )
          )
        )
      )
    )
  )

  loadChargeCodesVarianceReasonsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chargeCodesActions.loadChargeCodesVarianceReasonsFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load variance reasons."
          })
        })
      ),
    { dispatch: false }
  )

  loadBaseChargeCodes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chargeCodesActions.loadBaseChargeCodes),
      exhaustMap(() =>
        this.chargeCodesService.getBaseChargeTypes().pipe(
          map(response =>
            response.map(
              item =>
                <ChargeCode>{
                  id: item.chargeTypeId,
                  tenantChargeTypes: item.tenantChargeTypes.map(
                    tenantChargeType =>
                      <ChargeCode['tenantChargeTypes'][number]>{
                        id: tenantChargeType.uuid,
                        code: tenantChargeType.code,
                        description: tenantChargeType.description,
                        isDefault: tenantChargeType.isDefault
                      }
                  )
                }
            )
          ),
          map(chargeTypes =>
            chargeCodesActions.loadBaseChargeCodesSuccess({
              chargeTypes
            })
          ),
          catchError(error =>
            of(
              chargeCodesActions.loadBaseChargeCodesFail({
                error
              })
            )
          )
        )
      )
    )
  )

  loadBaseChargeCodesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chargeCodesActions.loadBaseChargeCodesFail),
        tap(() => {
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load tenant charges types."
          })
        })
      ),
    { dispatch: false }
  )
}
