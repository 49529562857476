import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { ChargeCodesForm } from '../domain/charge-codes-form.model'
import { HttpErrorResponse } from '@angular/common/http'
import { AsyncOperations } from '../domain/charge-codes-loading.model'
import { LoadingStatuses } from '@navix/shared/loading'
import { ChargeCode } from '../domain/charge-type.model'

export const chargeCodesActions = createActionGroup({
  source: 'Charge Codes',
  events: {
    'set loading': props<{
      operation: AsyncOperations
      loading: LoadingStatuses
      message?: string
    }>(),
    'add tenant charge code':
      props<Pick<ChargeCodesForm, 'tenantCode' | 'chargeTypeId'>>(),
    'add tenant charge code success': emptyProps(),
    'add tenant charge code fail': props<{
      error: HttpErrorResponse
    }>(),
    'update tenant charge code': props<ChargeCodesForm>(),
    'update tenant charge code success': emptyProps(),
    'update tenant charge code fail': props<{
      error: HttpErrorResponse | Error
    }>(),
    'toggle default charge code': props<{
      id: string
      isDefault: boolean
    }>(),
    'toggle default charge code success': props<{
      id: string
      isDefault: boolean
    }>(),
    'toggle default charge code fail': props<{
      error: HttpErrorResponse | Error
    }>(),
    'delete tenant charge code': props<{ id: string }>(),
    'delete tenant charge code success': emptyProps(),
    'delete tenant charge code fail': props<{
      error: HttpErrorResponse | Error
    }>(),
    'load accessorial charge codes': emptyProps(),
    'load accessorial charge codes success': props<{
      chargeTypes: ChargeCode[]
    }>(),
    'load accessorial charge codes fail': props<{ error: unknown }>(),
    'load charge codes variance reasons': emptyProps(),
    'load charge codes variance reasons success': props<{
      chargeTypes: ChargeCode[]
    }>(),
    'load charge codes variance reasons fail': props<{ error: unknown }>(),

    'load base charge codes': emptyProps(),
    'load base charge codes success': props<{
      chargeTypes: ChargeCode[]
    }>(),
    'load base charge codes fail': props<{ error: unknown }>()
  }
})
