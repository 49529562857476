import { LoadingState } from '@navix/shared/loading'

export enum AsyncOperations {
  loadVendorChargeCodes = 'loadVendorChargeCodes',
  loadVendorChargeCodesById = 'loadVendorChargeCodesById',
  addTenantChargeCode = 'addTenantChargeCode',
  toggleDefaultChargeCode = 'toggleDefaultChargeCode',
  updateTenantChargeCode = 'updateTenantChargeCode',
  deleteTenantChargeCode = 'deleteTenantChargeCode',
  updateVendorChargeCode = 'updateVendorChargeCode',
  deleteVendorChargeCode = 'deleteVendorChargeCode'
}

type AsyncOperationsMetadata = {
  [key in keyof typeof AsyncOperations]: unknown
} & {
  readonly [AsyncOperations.addTenantChargeCode]: {
    statusCode: number
  }
}

export type ChargesLoadingState = LoadingState<
  typeof AsyncOperations,
  AsyncOperationsMetadata
>
