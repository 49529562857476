import { Action, createReducer, on } from '@ngrx/store'
import { chargeCodesActions } from './charge-codes.actions'
import {
  LoadingStatuses,
  getDefaultLoadingState,
  updateLoadingState
} from '@navix/shared/loading'
import {
  AsyncOperations,
  ChargesLoadingState
} from '../domain/charge-codes-loading.model'
import { ChargeCode } from '../domain/charge-type.model'
import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity'
import {
  vendorChargeCodesAdapter,
  vendorChargeCodesReducers
} from './vendor-charge-codes'
import { VendorChargeCode } from '../domain/vendor-charge-code.model'

export const chargeCodesAdapter: EntityAdapter<ChargeCode> =
  createEntityAdapter<ChargeCode>()

export const CHARGE_CODES_FEATURE_KEY = 'feature-charge-codes'

export interface ChargeCodesState extends EntityState<ChargeCode> {
  loading: ChargesLoadingState
  vendorChargeCodes: EntityState<VendorChargeCode>
}

export const initialChargeCodesState: ChargeCodesState =
  chargeCodesAdapter.getInitialState({
    loading: getDefaultLoadingState(AsyncOperations),
    vendorChargeCodes: vendorChargeCodesAdapter.getInitialState()
  })

const reducer = createReducer(
  initialChargeCodesState,
  ...vendorChargeCodesReducers,
  on(
    chargeCodesActions.setLoading,
    (state, { operation, loading, message }): ChargeCodesState => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation,
        status: loading,
        message: message ?? ''
      })
    })
  ),
  on(chargeCodesActions.addTenantChargeCode, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.addTenantChargeCode,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    chargeCodesActions.addTenantChargeCodeSuccess,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.addTenantChargeCode,
        status: LoadingStatuses.Completed,
        message: 'Mapping created successfully.'
      })
    })
  ),
  on(
    chargeCodesActions.addTenantChargeCodeFail,
    (state: ChargeCodesState, httpError) => {
      const message = String(httpError.error)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.addTenantChargeCode,
          status: LoadingStatuses.Failed,
          message: message ?? "There's an error trying to map a tenant charge.",
          metadata: {
            statusCode: httpError.error.status
          }
        })
      }
    }
  ),
  on(chargeCodesActions.toggleDefaultChargeCode, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.toggleDefaultChargeCode,
      status: LoadingStatuses.InProgress
    })
  })),

  on(
    chargeCodesActions.toggleDefaultChargeCodeSuccess,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.toggleDefaultChargeCode,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(chargeCodesActions.updateTenantChargeCode, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.updateTenantChargeCode,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    chargeCodesActions.updateTenantChargeCodeSuccess,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateTenantChargeCode,
        status: LoadingStatuses.Completed,
        message: 'Mapping updated successfully.'
      })
    })
  ),
  on(
    chargeCodesActions.updateTenantChargeCodeFail,
    (state: ChargeCodesState, httpError) => {
      const message = (httpError.error as unknown as { errorMessage: string }[])
        .map(x => x.errorMessage)
        .join(', ')
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.updateTenantChargeCode,
          status: LoadingStatuses.Failed,
          message: message ?? "There's an error trying to map a tenant charge."
        })
      }
    }
  ),
  on(
    chargeCodesActions.toggleDefaultChargeCodeFail,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.toggleDefaultChargeCode,
        status: LoadingStatuses.Failed,
        message: 'There was an error trying to toggle default charge code.'
      })
    })
  ),
  on(
    chargeCodesActions.loadAccessorialChargeCodesSuccess,
    (state, { chargeTypes }) => {
      const fixedChargeTypes = chargeTypes.map(chargeType => ({
        ...chargeType,
        ...(state.entities[chargeType.id] ?? {}),
        tenantChargeTypes:
          chargeType.tenantChargeTypes.length === 0
            ? state.entities[chargeType.id]?.tenantChargeTypes ?? []
            : chargeType.tenantChargeTypes
      }))

      return chargeCodesAdapter.upsertMany(fixedChargeTypes, state)
    }
  ),
  on(
    chargeCodesActions.loadChargeCodesVarianceReasonsSuccess,
    (state, { chargeTypes }) => {
      const fixedChargeTypes = chargeTypes.map(chargeType => ({
        ...chargeType,
        ...(state.entities[chargeType.id] ?? {}),
        varianceReasons: chargeType.varianceReasons
      }))
      return chargeCodesAdapter.upsertMany(fixedChargeTypes, state)
    }
  ),
  on(
    chargeCodesActions.loadBaseChargeCodesSuccess,
    (state, { chargeTypes }) => {
      const fixedChargeTypes = chargeTypes.map(chargeType => ({
        ...chargeType,
        ...(state.entities[chargeType.id] ?? {}),
        tenantChargeTypes:
          chargeType.tenantChargeTypes.length === 0
            ? state.entities[chargeType.id]?.tenantChargeTypes ?? []
            : chargeType.tenantChargeTypes
      }))

      return chargeCodesAdapter.upsertMany(fixedChargeTypes, state)
    }
  ),
  on(chargeCodesActions.deleteTenantChargeCode, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.deleteTenantChargeCode,
      status: LoadingStatuses.InProgress
    })
  })),
  on(
    chargeCodesActions.deleteTenantChargeCodeSuccess,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.deleteTenantChargeCode,
        status: LoadingStatuses.Completed,
        message: 'Mapping deleted successfully.'
      })
    })
  ),
  on(
    chargeCodesActions.deleteTenantChargeCodeFail,
    (state: ChargeCodesState, httpError) => {
      const message = String(httpError.error)
      return {
        ...state,
        loading: updateLoadingState(state.loading, {
          operation: AsyncOperations.deleteTenantChargeCode,
          status: LoadingStatuses.Failed,
          message:
            message ?? "There's an error trying to delete a tenant charge."
        })
      }
    }
  )
)

export function chargecodesReducer(state: ChargeCodesState, action: Action) {
  return reducer(state, action)
}
