import { IStrategy } from '@navix/utils/adapter'
import { ChargeCodesForm } from '../domain/charge-codes-form.model'
import { UpdateTenantChargeCodeRequest } from '../domain/edit-tenant-charge-code.request'

type Input = ChargeCodesForm

export class ToUpdateTenantChargeCodesRequest
  implements IStrategy<Input, UpdateTenantChargeCodeRequest>
{
  convert(response: Input): UpdateTenantChargeCodeRequest {
    if (response.chargeTypeId === undefined)
      throw new Error('chargeTypeId is undefined')
    return {
      tenantChargeTypeUuid: response.tenantCode.id!,
      tenantCode: response.tenantCode.code,
      tenantDescription: response.tenantCode.description,
      chargeTypeId: response.chargeTypeId,
      isDefault: response.tenantCode.isDefault,
      isOverride: response.tenantCode.isOverride
    }
  }
}
