import { GetVendorChargeTypesByIdResponse } from '../domain/get-vendor-charges-types-by-id.response'
import { VendorChargeCode } from '../domain/vendor-charge-code.model'

export default function fromGetVendorChargeTypesByIdResponse(
  request: GetVendorChargeTypesByIdResponse
): VendorChargeCode {
  return {
    id: request.uuid,
    vendorDescription: request.vendorDescription,
    code: request.code,
    description: request.description,
    tenantChargeTypeId: request.tenantChargeTypeUuid,
    chargeCode: {
      id: request.chargeTypeId,
      description: request.navixChargeDescription
    }
  }
}
