import { AddVendorChargeCodeRequest } from '../domain/add-vendor-charge-code.request'
import { VendorChargeCodesForm } from '../domain/vendor-charge-codes.form.model'

export default function ToAddVendorChargeTypesRequest(
  request: Pick<
    VendorChargeCodesForm,
    'vendorId' | 'code' | 'description' | 'chargeTypeId'
  >
): AddVendorChargeCodeRequest {
  return {
    vendorId: request.vendorId ?? 0,
    code: request.code,
    description: request.description,
    chargeTypeId: Number(request.chargeTypeId)
  }
}
