import { Injectable, inject } from '@angular/core'
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import { ChargeCodesFormControls } from '../domain/charge-codes-form.model'

@Injectable({
  providedIn: 'root'
})
export class ChargeCodesFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getAddForm(): FormGroup<
    Pick<ChargeCodesFormControls, 'tenantCode' | 'chargeTypeId'>
  > {
    return this.fb.group({
      tenantCode: this.fb.group({
        id: this.fb.control<string | undefined>(''),
        code: this.fb.control<string>('', Validators.required),
        description: this.fb.control<string>('', Validators.required),
        isDefault: this.fb.control<boolean>(true),
        isOverride: this.fb.control<boolean>(false)
      }),

      chargeTypeId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      )
    })
  }

  getEditForm(): FormGroup<ChargeCodesFormControls> {
    return this.fb.group({
      tenantCode: this.fb.group({
        id: this.fb.control<string | undefined>('', Validators.required),
        code: this.fb.control<string>('', Validators.required),
        description: this.fb.control<string>('', Validators.required),
        isDefault: this.fb.control<boolean>(false),
        isOverride: this.fb.control<boolean>(false)
      }),

      chargeTypeId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      )
    })
  }
}
