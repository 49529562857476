import { createFeatureSelector, createSelector } from '@ngrx/store'
import { chargeCodesAdapter } from './charge-codes.reducer'
import {
  CHARGE_CODES_FEATURE_KEY,
  ChargeCodesState
} from './charge-codes.reducer'
import { vendorChargeCodesAdapter } from './vendor-charge-codes'
import { KnownChageTypes } from './static/known-chage-types'

export const selectChargeCodesState = createFeatureSelector<ChargeCodesState>(
  CHARGE_CODES_FEATURE_KEY
)

export const selectLoading = createSelector(
  selectChargeCodesState,
  (state: ChargeCodesState) => state.loading
)
const chargeCodesSelectors = chargeCodesAdapter.getSelectors()
const vendorChargeCodesSelectors = vendorChargeCodesAdapter.getSelectors()

export const selectAllChargeCodes = createSelector(
  selectChargeCodesState,
  (state: ChargeCodesState) => chargeCodesSelectors.selectAll(state)
)

export const selectAllChargeCodesWithTenantChageCodes = createSelector(
  selectChargeCodesState,
  (state: ChargeCodesState) =>
    chargeCodesSelectors
      .selectAll(state)
      .filter(chargeCode => chargeCode.tenantChargeTypes?.length > 0)
)
export const selectAllChargeCodesEntities = createSelector(
  selectChargeCodesState,
  (state: ChargeCodesState) => state.entities
)

export const selectAllAccessorialChargeCodes = createSelector(
  selectChargeCodesState,
  (state: ChargeCodesState) =>
    chargeCodesSelectors
      .selectAll(state)
      .filter(
        chargeCode =>
          chargeCode.parentChargeTypeId === KnownChageTypes.Accesorials
      )
)

export const selectChargeCodesEntities = createSelector(
  selectChargeCodesState,
  (state: ChargeCodesState) => chargeCodesSelectors.selectEntities(state)
)

export const selectAllVendorChargeCodes = createSelector(
  selectChargeCodesState,
  (state: ChargeCodesState) =>
    vendorChargeCodesSelectors.selectAll(state.vendorChargeCodes)
)
