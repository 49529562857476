import { on } from '@ngrx/store'
import { ChargeCodesState } from '../charge-codes.reducer'
import { vendorChargeCodesActions } from './vendor-charge-codes.actions'
import { LoadingStatuses, updateLoadingState } from '@navix/shared/loading'
import { AsyncOperations } from '../../domain/charge-codes-loading.model'
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { VendorChargeCode } from '../../domain/vendor-charge-code.model'

export const vendorChargeCodesAdapter: EntityAdapter<VendorChargeCode> =
  createEntityAdapter<VendorChargeCode>()

export const vendorChargeCodesReducers = [
  on(vendorChargeCodesActions.load, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.loadVendorChargeCodes,
      status: LoadingStatuses.InProgress,
      message: undefined
    })
  })),
  on(
    vendorChargeCodesActions.loadSuccess,
    (state: ChargeCodesState, { vendorChargeCodes }) => ({
      ...state,
      vendorChargeCodes: vendorChargeCodesAdapter.setAll(
        vendorChargeCodes,
        state.vendorChargeCodes
      ),
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadVendorChargeCodes,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(vendorChargeCodesActions.loadFail, (state: ChargeCodesState, error) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.loadVendorChargeCodes,
      status: LoadingStatuses.Failed,
      message: error.message ?? 'Failed to load vendor charge codes.'
    })
  })),
  on(
    vendorChargeCodesActions.reserLoadLoadingState,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadVendorChargeCodes,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(vendorChargeCodesActions.loadById, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.loadVendorChargeCodesById,
      status: LoadingStatuses.InProgress,
      message: undefined
    })
  })),
  on(
    vendorChargeCodesActions.loadByIdSuccess,
    (state: ChargeCodesState, { vendorChargeCode }) => ({
      ...state,
      vendorChargeCodes: vendorChargeCodesAdapter.upsertOne(
        vendorChargeCode,
        state.vendorChargeCodes
      ),
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadVendorChargeCodesById,
        status: LoadingStatuses.Completed
      })
    })
  ),
  on(
    vendorChargeCodesActions.loadByIdFail,
    (state: ChargeCodesState, error) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadVendorChargeCodesById,
        status: LoadingStatuses.Failed,
        message: error.message ?? 'Failed to load vendor charge codes by Id.'
      })
    })
  ),
  on(
    vendorChargeCodesActions.resetLoadByIdLoadingState,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.loadVendorChargeCodesById,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(vendorChargeCodesActions.update, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.updateVendorChargeCode,
      status: LoadingStatuses.InProgress
    })
  })),
  on(vendorChargeCodesActions.updateSuccess, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.updateVendorChargeCode,
      status: LoadingStatuses.Completed,
      message: 'Vendor charge code updated successfully.'
    })
  })),
  on(vendorChargeCodesActions.updateFail, (state: ChargeCodesState, error) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.updateVendorChargeCode,
      status: LoadingStatuses.Failed,
      message:
        error.message ?? "There's an error trying to update a vendor charge."
    })
  })),
  on(
    vendorChargeCodesActions.resetUpdateLoadingState,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.updateVendorChargeCode,
        status: LoadingStatuses.NotStarted
      })
    })
  ),
  on(vendorChargeCodesActions.delete, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.deleteVendorChargeCode,
      status: LoadingStatuses.InProgress
    })
  })),
  on(vendorChargeCodesActions.deleteSuccess, (state: ChargeCodesState) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.deleteVendorChargeCode,
      status: LoadingStatuses.Completed,
      message: 'Vendor charge code deleted successfully.'
    })
  })),
  on(vendorChargeCodesActions.deleteFail, (state: ChargeCodesState, error) => ({
    ...state,
    loading: updateLoadingState(state.loading, {
      operation: AsyncOperations.deleteVendorChargeCode,
      status: LoadingStatuses.Failed,
      message:
        error.message ?? "There's an error trying to delete a vendor charge."
    })
  })),
  on(
    vendorChargeCodesActions.resetDeleteLoadingState,
    (state: ChargeCodesState) => ({
      ...state,
      loading: updateLoadingState(state.loading, {
        operation: AsyncOperations.deleteVendorChargeCode,
        status: LoadingStatuses.NotStarted
      })
    })
  )
]
