import { Injectable, Signal, computed, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { chargeCodesActions } from './charge-codes.actions'
import { ChargeCodesForm } from '../domain/charge-codes-form.model'
import * as ChargeCodeSelectors from './charge-codes.selectors'
import {
  AsyncOperations,
  ChargesLoadingState
} from '../domain/charge-codes-loading.model'
import { loadingStateEqualFn, LoadingStatuses } from '@navix/shared/loading'
import { filter, map } from 'rxjs'
import { vendorChargeCodesActions } from './vendor-charge-codes'
import { selectAllVendorChargeCodes } from './charge-codes.selectors'
import { VendorChargeCodesForm } from '../domain/vendor-charge-codes.form.model'
import { getRouterSelectors } from '@ngrx/router-store'
import { ChargeCodeGrouped } from '../domain/charge-type.model'
import { knownAccessorialsGroups } from './static/known-accessorials-groups'

const { selectQueryParams, selectRouteData, selectRouteParams } =
  getRouterSelectors()

@Injectable()
export class ChargeCodesFacade {
  private readonly store: Store = inject(Store)

  routeData$ = this.store.select(selectRouteData)
  routeQueryParams$ = this.store.select(selectQueryParams)
  routeParams$ = this.store.select(selectRouteParams)

  loading$ = this.store.select(ChargeCodeSelectors.selectLoading)
  loading = this.store.selectSignal(ChargeCodeSelectors.selectLoading)
  allChargeTypes$ = this.store
    .select(ChargeCodeSelectors.selectAllChargeCodes)
    .pipe(filter(data => data.length > 0))
  allChargeTypesWithTenantChargeCodes$ = this.store.select(
    ChargeCodeSelectors.selectAllChargeCodesWithTenantChageCodes
  )

  allChargeTypes = this.store.selectSignal(
    ChargeCodeSelectors.selectAllChargeCodes
  )

  allChargeTypeEntities = this.store.selectSignal(
    ChargeCodeSelectors.selectAllChargeCodesEntities
  )

  allAccessorialChargeTypes$ = this.store
    .select(ChargeCodeSelectors.selectAllAccessorialChargeCodes)
    .pipe(filter(data => data.length > 0))

  allAcccessorialChargeTypes = this.store.selectSignal(
    ChargeCodeSelectors.selectAllAccessorialChargeCodes
  )

  allAccessorialsChargeTypesGrouped: Signal<ChargeCodeGrouped[]> = computed(
    () => {
      return Object.entries(knownAccessorialsGroups).map<ChargeCodeGrouped>(
        ([key, value]) => ({
          id: Number(key),
          name: value,
          chageCodes: this.allChargeTypes()
            .filter(
              chargeType =>
                chargeType.groupId === Number(key) &&
                chargeType.tenantChargeTypes.length > 0
            )
            .toSorted((compare, comparer) =>
              compare.description.localeCompare(comparer.description)
            )
        })
      )
    }
  )

  allChargeTypesDictionary$ = this.store
    .select(ChargeCodeSelectors.selectChargeCodesState)
    .pipe(
      filter(chargeTypes => chargeTypes.ids.length > 0),
      map(chargeTypes => chargeTypes.entities)
    )

  allChargeVarianceReason$ = this.store
    .select(ChargeCodeSelectors.selectAllChargeCodes)
    .pipe(
      map(chargeTypes => chargeTypes.flatMap(charge => charge.varianceReasons))
    )

  allChargeTypesWithCodes = computed(() =>
    this.allChargeTypes().filter(
      chargeType => chargeType.tenantChargeTypes?.length > 0
    )
  )

  allVendorChargeCodes$ = this.store.select(selectAllVendorChargeCodes)

  allVendorChargeCodes = this.store.selectSignal(selectAllVendorChargeCodes)

  loadChargeCodesWithDetails() {
    this.loadChargeCodes()
    this.store.dispatch(chargeCodesActions.loadChargeCodesVarianceReasons())
  }

  loadVendorChargeCodes() {
    this.store.dispatch(vendorChargeCodesActions.load())
  }

  loadChargeCodes() {
    this.store.dispatch(chargeCodesActions.loadAccessorialChargeCodes())
    this.store.dispatch(chargeCodesActions.loadBaseChargeCodes())
  }
  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      chargeCodesActions.setLoading({
        operation,
        loading: LoadingStatuses.NotStarted
      })
    )
  }

  addTenantChargeCode(
    chargeCode: Pick<ChargeCodesForm, 'chargeTypeId' | 'tenantCode'>
  ) {
    this.store.dispatch(chargeCodesActions.addTenantChargeCode(chargeCode))
  }

  updateTenantChargeCode(chargeCode: ChargeCodesForm) {
    this.store.dispatch(chargeCodesActions.updateTenantChargeCode(chargeCode))
  }

  toggleDefaultChargeCode(tenantChargeCodeId: string, isDefault: boolean) {
    this.store.dispatch(
      chargeCodesActions.toggleDefaultChargeCode({
        id: tenantChargeCodeId,
        isDefault
      })
    )
  }

  deleteTenantChargeCode(id: string) {
    this.store.dispatch(chargeCodesActions.deleteTenantChargeCode({ id }))
  }

  updateVendorChargeCode(
    vendorChargeCode: Pick<
      VendorChargeCodesForm,
      'vendorChargeTypeId' | 'chargeTypeId'
    >
  ) {
    this.store.dispatch(vendorChargeCodesActions.update({ vendorChargeCode }))
  }

  loadVendorChargeCodeById(id: string) {
    this.store.dispatch(vendorChargeCodesActions.loadById({ id }))
  }

  deleteVendorChargeCode(id: string) {
    this.store.dispatch(vendorChargeCodesActions.delete({ id }))
  }

  addVendorChargeCode(
    chargeCode: Pick<
      VendorChargeCodesForm,
      'vendorId' | 'code' | 'description' | 'chargeTypeId'
    >
  ) {
    this.store.dispatch(
      vendorChargeCodesActions.add({ vendorChargeCode: chargeCode })
    )
  }

  getLoadingStateSignal<K extends keyof typeof AsyncOperations>(operation: K) {
    return computed(() => this.loading()[operation], {
      equal: loadingStateEqualFn
    }) satisfies Signal<ChargesLoadingState[K]>
  }
}
