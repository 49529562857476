import { Injectable, inject } from '@angular/core'
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms'
import { VendorChargeCodesFormControls } from '../domain/vendor-charge-codes.form.model'

@Injectable({
  providedIn: 'root'
})
export class VendorChargeCodesFormService {
  private readonly fb = inject(NonNullableFormBuilder)

  getEditForm(): FormGroup<
    Pick<VendorChargeCodesFormControls, 'vendorChargeTypeId' | 'chargeTypeId'>
  > {
    return this.fb.group({
      vendorChargeTypeId: this.fb.control<string>('', Validators.required),
      chargeTypeId: this.fb.control<number | undefined>(
        undefined,
        Validators.required
      )
    })
  }
}
