import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { HttpErrorResponse } from '@angular/common/http'
import { VendorChargeCode } from '../../domain/vendor-charge-code.model'
import { VendorChargeCodesForm } from '../../domain/vendor-charge-codes.form.model'

export const vendorChargeCodesActions = createActionGroup({
  source: 'Vendor Charge Codes',
  events: {
    load: emptyProps(),
    loadSuccess: props<{
      vendorChargeCodes: Array<VendorChargeCode>
    }>(),
    loadFail: props<HttpErrorResponse | Error>(),
    reserLoadLoadingState: emptyProps(),
    loadById: props<{ id: string }>(),
    loadByIdSuccess: props<{ vendorChargeCode: VendorChargeCode }>(),
    loadByIdFail: props<HttpErrorResponse | Error>(),
    resetLoadByIdLoadingState: emptyProps(),
    update: props<{
      vendorChargeCode: Pick<
        VendorChargeCodesForm,
        'vendorChargeTypeId' | 'chargeTypeId'
      >
    }>(),
    updateSuccess: emptyProps(),
    updateFail: props<HttpErrorResponse | Error>(),
    resetUpdateLoadingState: emptyProps(),
    delete: props<{ id: string }>(),
    deleteSuccess: emptyProps(),
    deleteFail: props<HttpErrorResponse | Error>(),
    resetDeleteLoadingState: emptyProps(),
    add: props<{
      vendorChargeCode: Pick<
        VendorChargeCodesForm,
        'vendorId' | 'code' | 'description' | 'chargeTypeId'
      >
    }>(),
    addSuccess: emptyProps(),
    addFail: props<HttpErrorResponse | Error>(),
    resetAddLoadingState: emptyProps()
  }
})
