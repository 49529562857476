import { makeEnvironmentProviders } from '@angular/core'
import { ChargeCodesEffects } from '../+state/charge-codes.effects'
import { chargecodesReducer } from '../+state/charge-codes.reducer'
import { ChargeCodesFacade } from '../+state/charge-codes.facade'
import { provideState } from '@ngrx/store'
import { provideEffects } from '@ngrx/effects'
import { vendorChargesCodesEffects } from '../+state/vendor-charge-codes'
import { CHARGE_CODES_FEATURE_KEY } from '../+state/charge-codes.reducer'

export function providChargeCodesFeature() {
  return makeEnvironmentProviders([
    provideEffects(ChargeCodesEffects, vendorChargesCodesEffects),
    provideState(CHARGE_CODES_FEATURE_KEY, chargecodesReducer),
    ChargeCodesFacade
  ])
}
