import { IStrategy } from '@navix/utils/adapter'
import { AddTenantChargeCodeRequest } from '../domain/add-tenant-charge-code.request'
import { ChargeCodesForm } from '../domain/charge-codes-form.model'

type Input = Pick<ChargeCodesForm, 'tenantCode' | 'chargeTypeId'>

export class ToAddTenantChargeCodesRequest
  implements IStrategy<Input, AddTenantChargeCodeRequest>
{
  convert(response: Input): AddTenantChargeCodeRequest {
    if (response.chargeTypeId === undefined)
      throw new Error('chargeTypeId is undefined')
    return {
      tenantCode: response.tenantCode.code,
      tenantDescription: response.tenantCode.description,
      chargeTypeId: response.chargeTypeId,
      isDefault: response.tenantCode.isDefault
    }
  }
}
